import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Roles, RoleService } from '@app/core/services/role.service';
import { FeatureFlagEnum } from '@app/shared/FeatureFlags/feature-flag.enum';
import { FeatureFlagService } from '@app/shared/FeatureFlags/services/feature-flag.service';
import { result, values } from 'lodash';
import { combineLatest, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(
    private featureFlagService: FeatureFlagService,
    private roleService: RoleService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const roles: Roles[] = route.data['role'] as Roles[];

    if (!Array.isArray(roles)) {
      return false;
    }

    const featureFlag: FeatureFlagEnum = route.data[
      'featureFlag'
    ] as FeatureFlagEnum;

    const featureFlagCheck$ =
      featureFlag === undefined
        ? of(true)
        : this.featureFlagService.isEnabled(featureFlag);

    const roleCheck$ = combineLatest(
      roles.map((x) => this.roleService.hasRole(x))
    ).pipe(map((result) => result.some((element) => element)));

    return combineLatest([featureFlagCheck$, roleCheck$]).pipe(
      map(([featureFlagResult, roleResult]) => {
        const hasAccess = featureFlagResult && roleResult;

        if (!roleResult) {
          this.router.navigate(['not-found']);
        }
        if (!featureFlagResult) {
          let featureName =
            this.featureFlagService.featureFlagWithFeatureNames.find(
              (e) => e.featureFlag == featureFlag
            )?.featureName ?? '';

          this.router.navigate(['home/feature-disabled'], {
            queryParams: { featureName: featureName },
          });
        }
        return hasAccess;
      })
    );
  }
}
